import { FormattedMessage } from 'react-intl';

import { UserOutlined, FileOutlined, BarsOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import { NavItemType } from 'types/menu';

const icons = {
  FileOutlined,
  UserOutlined,
  BarsOutlined,
  AppstoreAddOutlined
};

const applications: NavItemType = {
  id: 'group-applications',
  title: <FormattedMessage id="applications" />,
  icon: icons.AppstoreAddOutlined,
  type: 'group',
  children: [
    {
      id: 'patient-list',
      title: <FormattedMessage id="patient-list" />,
      type: 'item',
      icon: icons.UserOutlined,
      url: '/apps/patients/list'
    },
    {
      id: 'recent-assessment-list',
      title: <FormattedMessage id="recent-assessment-list" />,
      type: 'item',
      icon: icons.BarsOutlined,
      url: '/apps/assessment/recent'
    },
    {
      id: 'templates-list',
      title: <FormattedMessage id="assessment" />,
      type: 'item',
      icon: icons.FileOutlined,

      url: '/apps/assessment/assessment-list'
    }
  ]
};

export default applications;
