// third-party
import { createSlice } from '@reduxjs/toolkit';

// types

const initialState = {
  isOpen: false,
  isCustomerOpen: false,
  open: false,
  lists: [],
  list: null,
  error: null,
  alertPopup: false
};

// ==============================|| INVOICE - SLICE ||============================== //

const patient = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    // is customer open
    customerPopup(state, action) {
      state.isCustomerOpen = action.payload.isCustomerOpen;
    },

    // handler customer form popup
    toggleCustomerPopup(state, action) {
      state.open = action.payload.open;
    },

    hasError(state, action) {
      state.error = action.payload.error;
    },

    // get all invoice list
    getLists(state, action) {
      state.lists = action.payload;
    },

    // delete invoice
    deletePatient(state, action) {
      const { invoiceId } = action.payload;
      const deleteInvoice = state.lists.filter((list: any) => list.id !== invoiceId);
      state.lists = deleteInvoice;
    },

    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

export default patient.reducer;

export const { customerPopup, toggleCustomerPopup, getLists, alertPopupToggle } = patient.actions;
