// material-ui
import { useTheme } from '@mui/material/styles';
import { ThemeMode } from 'types/config';

import logoDark from 'assets/images/logo.png';
import logo from 'assets/images/logo.png';

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  const theme = useTheme();
  return <img src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo} alt="Mantis" width="100" />;
};

export default LogoMain;
