import { combineReducers } from 'redux';

import menu from './menu';
import patient from './patient';
import snackbar from './snackbar';

const reducers = combineReducers({
  menu,
  snackbar,
  patient
});

export default reducers;
