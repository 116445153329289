import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const AppAssessmentList = Loadable(lazy(() => import('pages/apps/assessment/list')));

const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));

const PatientListPage = Loadable(lazy(() => import('pages/apps/patient/lists')));
const PatientDetailsPage = Loadable(lazy(() => import('pages/apps/patient/details')));
const PatientAssessmentDetailsPage = Loadable(lazy(() => import('pages/apps/assessment/details')));
const RecentAssessments = Loadable(lazy(() => import('pages/apps/assessment/recent')));

const AboutUsPage = Loadable(lazy(() => import('pages/extra-pages/about-us')));

const AccountProfile = Loadable(lazy(() => import('pages/apps/profiles/account')));
const AccountTabAccount = Loadable(lazy(() => import('sections/apps/profiles/account/TabAccount')));
const AccountTabPassword = Loadable(lazy(() => import('sections/apps/profiles/account/TabPassword')));

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'apps',
          children: [
            {
              path: 'profiles',
              children: [
                {
                  path: 'account',
                  element: <AccountProfile />,
                  children: [
                    {
                      path: 'my-account',
                      element: <AccountTabAccount />
                    },
                    {
                      path: 'password',
                      element: <AccountTabPassword />
                    }
                  ]
                }
              ]
            },
            {
              path: 'patients',
              children: [
                {
                  path: 'list',
                  element: <PatientListPage />
                },
                {
                  path: 'details/:id',
                  element: <PatientDetailsPage />
                }
              ]
            },
            {
              path: 'assessment',
              children: [
                {
                  path: 'assessment-list',
                  element: <AppAssessmentList />
                },
                {
                  path: 'details/:id',
                  element: <PatientAssessmentDetailsPage />
                },
                {
                  path: 'recent',
                  element: <RecentAssessments />
                }
              ]
            }
          ]
        },
        {
          path: 'about-us',
          element: <AboutUsPage />
        }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        }
      ]
    },
    {
      path: '/auth',
      element: <CommonLayout />,
      children: [
        {
          path: 'login',
          element: <AuthLogin />
        },
        {
          path: 'register',
          element: <AuthRegister />
        },
        {
          path: 'forgot-password',
          element: <AuthForgotPassword />
        },
        {
          path: 'reset-password',
          element: <AuthResetPassword />
        },
        {
          path: 'check-mail',
          element: <AuthCheckMail />
        },
        {
          path: 'code-verification',
          element: <AuthCodeVerification />
        }
      ]
    }
  ]
};

export default MainRoutes;
