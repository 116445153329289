import { lazy } from 'react';

import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const TherapistAssessmentPage = Loadable(lazy(() => import('pages/therapist/assessment')));

const AssessmentRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <CommonLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'therapist-assessment/:id',
          element: <TherapistAssessmentPage />
        }
      ]
    }
  ]
};

export default AssessmentRoutes;
