import { useRoutes } from 'react-router-dom';

import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import PatientRoutes from './PatientRoutes';
import AssessmentRoutes from './AssessmentRouters';

export default function ThemeRoutes() {
  return useRoutes([LoginRoutes, PatientRoutes, MainRoutes, AssessmentRoutes]);
}
