import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router';
import { EditOutlined, LogoutOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

interface Props {
  handleLogout: () => void;
}

const ProfileTab = ({ handleLogout }: Props) => {
  const navigation = useNavigate();
  const intl = useIntl();

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      <ListItemButton onClick={(event: React.MouseEvent<HTMLDivElement>) => navigation(`/apps/profiles/account/my-account`)}>
        <ListItemIcon>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText primary={intl.formatMessage({ id: 'edit-profile' })} />
      </ListItemButton>
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary={intl.formatMessage({ id: 'logout' })} />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
